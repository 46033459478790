<script setup>
import { defineModel, watch, ref } from 'vue'

const model = defineModel({ default: '' })
let localValue = ref('')
const emit = defineEmits(['change', 'update:modelValue'])
const debounce = (func, timeout) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, timeout);
    };
}
let props = defineProps({
    label: {
        type: String,
        default: ''
    },
    bgColor: {
        type: String,
        default: '#dadce0'
    },
    variant: {
        type: String,
        default: 'solo'
    },
    density: {
        type: String,
        default: 'default'
    },
    rounded: {
        type: Boolean,
        default: true
    },
    hideDetails: {
        type: Boolean,
        default: false
    },
    showIcon: {
        type: Boolean,
        default: false
    },
    type: {
        type: String,
        default: 'text'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    debounce: {
        type: Number,
        default: 0
    },

})
watch(() => model.value, (newValue) => {
    localValue.value = newValue
}, { immediate: true });

const debouncedInput = debounce((e) => {
    emit('change', e)
    emit("update:modelValue", e)
}, props.debounce);


</script>

<template>
         

    <v-text-field :type="type" :disabled="disabled" :prepend-inner-icon="showIcon ? 'mdi-magnify' : null"
        :hide-details="hideDetails" @update:modelValue="debouncedInput" :density="density" :rounded="rounded"
        :variant="variant" :bg-color="bgColor" single-line v-model="localValue" :label="label">
        <template v-slot:append>
            <slot name="append"></slot>

        </template>
    </v-text-field>
</template>

<style lang="scss" scoped>
@import "@/styles.scss";
</style>