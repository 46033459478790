// import { ref } from "vue";

const uniq = (array) => array
  .map((name) => {
    return {
      count: 1,
      name: name,
    };
  })
  .reduce((a, b) => {
    a[b.name] = (a[b.name] || 0) + b.count;
    return a;
  }, {});


  const makeId = (length) => {
    let result = "";
    let characters = "abcdefghijklmnopqrstuvwxyz";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  }
export function useHelpers() {


  return { uniq, makeId }
}