<template>


  <v-card flat :color="mainStore.themeSubColor" rounded="xl" class="text-left" :subtitle="helperText" :title="title">

    <v-card-text class="bg-surface-light pt-4">
      <slot name="content"></slot>
    </v-card-text>
  </v-card>




</template>


<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

defineProps({
  title: { Type: String },
  helperText: { Type: String, default: "" },
})

</script>


<style lang="scss" scoped>
@import "@/styles.scss";
</style>
