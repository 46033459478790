<template>
  <v-banner lines="one" density="compact">
    <v-banner-text :style="{ 'font-size': '18px', width: textWidthPercent + '%', 'text-align': 'left' }">
      <v-tooltip location="top" v-if="!hideTooltip">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="mr-4">mdi-information-outline</v-icon>


        </template>
        <span>{{ helperText }}</span>
      </v-tooltip>
      {{ title }}
    </v-banner-text>
    <v-text-field class="py-2" :disabled="!isAdmin" :hide-details="true" @update:modelValue="debouncedInput"
      density="compact" variant="solo" single-line v-model="localValue" :label="label" :type="type">

    </v-text-field>


  </v-banner>



</template>


<script setup>
import { defineModel, watch, ref } from 'vue'
import { useMainStore } from '@/stores/main'
const emit = defineEmits(['change', 'update:modelValue'])
const debounce = (func, timeout) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}
const mainStore = useMainStore()
const model = defineModel()
let localValue = ref('')

watch(() => model.value, (newValue) => {
  localValue.value = newValue
}, { immediate: true });

const debouncedInput = debounce((e) => {
  emit('change', e)
  emit("update:modelValue", e)
  incementChanges()
}, 200);


const incementChanges = () => {
  mainStore.INCREMENT_DEPLOY_CHANGES()
}
defineProps({
  title: { Type: String },
  isAdmin: { Type: Boolean },
  helperText: { Type: String, default: "" },
  label: { Type: String, default: "" },
  hideTooltip: { Type: Boolean, default: false },
  textWidthPercent: { Type: Number, default: 50 },
  type: { Type: String, default: "string" },
})


</script>


<style lang="scss" scoped>
@import "@/styles.scss";
</style>
