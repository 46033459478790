<template>
  <v-card flat :color="mainStore.themeSubColor" rounded="xl" class="text-left" :subtitle="text" :title="title">
    <v-card-text class="bg-surface-light pt-4">
      <v-combobox class="my-4" chips :multiple="allowMultiple" v-model="model" :label="label" persistent-hint />
    </v-card-text>


  </v-card>

</template>


<script setup>
// import DisplayHoverTooltip from "@/components/elements/display/DisplayHoverTooltip";
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

const model = defineModel()


defineProps({
  title: { Type: String },
  text: { Type: String, default: "" },

  allowMultiple: { Type: Boolean, default: true },
  label: { Type: String, default: "" },
})

</script>


<style lang="scss">
@import "@/styles.scss";
</style>
