<template>
  <v-select :disabled="disabled" :item-props.color="color" :base-color="color" :color="color" variant="outlined" v-model="model" :clearable="true" hide-details="auto" :items="items"
    :item-title="itemName" :item-value="itemValue" :multiple="multiple" :label="title">

  </v-select>
</template>

<script setup>
import { defineModel } from 'vue'

const model = defineModel()
defineProps({
  multiple: { type: Boolean, default: false },
  itemName: { type: String, default: "Name" },
  itemValue: { type: String, default: "Id" },
  disabled: { type: Boolean, default: false },
  color: {  },
  title: { type: String, default: "" },
  items: { type: Array, default: () => [] },
})

</script>

<style lang="scss">
@import "@/styles.scss";
</style>
