<template>
  <v-btn
    :outlined="outlined"
    rounded
    :disabled="disabled"
    :color="color"
  >
    {{ text }}
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "Cncpt-btn",
  props: {
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    textType: { type: Boolean, default: false },
    text: { type: String, default: "" },
    color: { default: 'primary'}
  },
  methods: {

  },
};
</script>

<style lang="scss">
@import "@/styles.scss";
</style>
