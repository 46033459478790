<template>


  <v-navigation-drawer v-model="model" floating color="#f8f8ff" :border="false" theme="light">
    <v-card flat color="transparent" class="mb-8 d-flex ml-4 mt-6">
      <h2>
        <b><a href="/" :style="{
          color: mainStore.mainColor,
          'text-decoration': 'none!important',
          fontSize: '16px',
        }">CENTRAL</a>
        </b>
      </h2>
    </v-card>
    <v-list>
      <div v-for="(item, i) in userStore.sidebarItemsFiltered" :key="i">
        <v-list-item v-if="!item.subLinks" :to="{ path: item.link }" :base-color="mainStore.mainColor"
          class="roundedCorners text-left" :prepend-icon="item.icon" :style="{ fontSize: '13px' }" :title="item.text">
          <template v-if="item.showAlerts" v-slot:append>
            <v-badge color="error" class="mx-2" :content="adminStore.anomaliesCount" /> </template>
        </v-list-item>
        <v-list-group :base-color="mainStore.mainColor" :value="item.text" :prepend-icon="item.icon" v-else>
          <template v-slot:activator="{ props }">
            <v-list-item density="compact" class="text-left" :style="{ fontSize: '13px' }" v-bind="props"
              :title="item.text"></v-list-item>
          </template>

          <v-list-item :active="userStore.subMenuName === sublink.subLinkName"
            @click="clickHandler(sublink.subLinkName)" :to="{ path: getPath(item) }" :base-color="mainStore.mainColor"
            class="roundedCorners text-left" v-for="(sublink, i) in getSubItems(item)" :key="i" :title="sublink.text"
            :value="sublink.text">

            <template v-if="sublink.showExperimentsCount" v-slot:append>
              <!-- <v-badge class="mx-2" :color="mainStore.mainColor" :content="mainStore.experimentCount || 0" /> -->
              <!-- <v-btn v-if="sublink.showExperimentsCount"  density="compact" color="orange-darken-2"> -->
                <v-badge class="mx-2" color="orange-darken-2" :content="mainStore.experimentCount || 0" >
                  <!-- <v-icon color="orange-darken-2" icon="mdi-star" start></v-icon> -->


                </v-badge>

                <!-- {{mainStore.experimentCount || 0}} -->
              <!-- </v-btn> -->
            </template>
            <template v-else-if="sublink.showAdunits" v-slot:append>
              <v-badge class="mx-2" :color="mainStore.mainColor" :content="mainStore.adunitCount || 0" />
            </template>
            <template v-else-if="sublink.showVideoUnits" v-slot:append>

              <v-badge :color="mainStore.mainColor" class="mx-2" :content="mainStore.videoAdunitCount || 0" />
            </template>
          </v-list-item>
        </v-list-group>
      </div>


    </v-list>


    <template v-slot:append>

      <LogOut></LogOut>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import LogOut from '@/components/logOut.vue';
import { useAdminStore } from '@/stores/admin'

const adminStore = useAdminStore()
import { defineModel } from 'vue'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'
const userStore = useUserStore()


const mainStore = useMainStore()

const getPath = (item) => {
  return item.noId
    ? `${item.link}/`
    : `${item.link}/${mainStore.site}`
}
const model = defineModel()

const getSubItems = (item) => {
  return item.subLinks.filter((link) => {
    if (link.hideConceptX && mainStore.isConceptX) return false
    if (link.adminOnly && !userStore.isAdmin) return false
    if (!link.dependsOnSetting) return true;

    return true
    // return selectedSiteObject?.settings[link.dependsOnSetting];
  })
}


// const removeOther = (name) => {
//   activeMain.value = name;
//   activeParent.value = null;
//   SubActive.value = {};
// }
const clickHandler = (newMenuName) => {
  userStore.changeSubMenu({ newSubMenuName: newMenuName })


}
// setSubMenuName({ newIndex, parent }) {
//   this.activeParent = parent.name;
//   this.activeMain = null;
//   let path = parent.noId ? parent.link : `${parent.link}/${this.site}`;


//   let currentRoute = this.$router.history.current.path;
//   console.log('currentRoute', currentRoute)
//   if (currentRoute !== path) {
//     // this.$router.push(objToPush);

//     this.$router.push({ path }) // -> /user/eduardo

//   }
//   console.log('NEW INDEX', newIndex)
//   this.$store.commit('CHANGE_MENU_INDEX', { newIndex });
// },
// const subToggle = (name) => {
//   for (let i = 0; i < Object.keys(SubActive.value).length; i++) {
//     if (name != Object.keys(SubActive.value)[i]) {
//       SubActive.value[Object.keys(SubActive.value)[i]] = false;
//     }
//   }
// }




</script>
<style lang="scss">
@import '@/styles.scss';

.v-navigation-drawer__border {
  display: none;
}

.logOutBtn {
  letter-spacing: normal;
  text-transform: none;
  margin-top: 25%;
  font-weight: bold;
}

.highlight-left {
  border-left-style: solid;
}

// .v-list-item__title.text-left {
//   font-weight: 500;
// }</style>
