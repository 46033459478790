<template>
  <v-container>
    <cncpt-text-field :disabled="true" :label="userStore.userEmail"></cncpt-text-field>
    <v-expansion-panels>
      <!-- <v-expansion-panel>
        <v-expansion-panel-title>
          <v-row align="center" class="spacer" no-gutters>
            <v-col sm="12" md="12">
              <strong>Color preference</strong>
              <span class="text-grey"> </span>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-divider></v-divider>
          <v-row class="pa-4">
            <v-list-subheader>Main color</v-list-subheader>
            <v-color-picker v-model="localUserSettings.topbarColor.hex" class="ma-2" mode="hexa"
              show-swatches></v-color-picker>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel> -->
      <v-expansion-panel hide-actions>
        <v-expansion-panel-title>
          <v-row align="center" class="spacer" no-gutters>
            <v-col sm="12" md="12">
              <strong>API KEY</strong>
              <span class="text-grey"> </span>
            </v-col>
          </v-row>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="11">

              <cncpt-textfield v-model="localUserSettings.apikey" :isAdmin="true" title="Api key"
                helperText="You can use your apikey to request report data through our API" />
            </v-col>
            <v-col cols="12" md="1">

              <v-btn class="mt-4" @click="createNewApiKey" variant="text"><v-icon>mdi-refresh</v-icon></v-btn>
            </v-col>
          </v-row>

<a target="_blank" :href="getApiUrl()">Report api</a>
        </v-expansion-panel-text>
      </v-expansion-panel>

    </v-expansion-panels>
    <v-btn @click="postChange" class="mt-4" variant="outlined" :color="mainStore.themeColor">Save
      preferences</v-btn>
  </v-container>
</template>
<script setup>
import { postUserSettings } from '@/api/background-api';
import { watchEffect, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const userStore = useUserStore()

import { useHelpers } from '@/composables/helpers.js'
const { makeId } = useHelpers()
const formatDate = date =>date.toISOString().split('T')[0];
const getApiUrl = () => {
  const today = new Date();
  const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  return `https://p5k8zvztq0.execute-api.eu-west-1.amazonaws.com/production/report/${formatDate(yesterday)}/${formatDate(today)}?apikey=${localUserSettings.value.apikey}`
}

let localUserSettings = ref({})
watchEffect(() => {
  localUserSettings.value = userStore.userSettings
})




const createNewApiKey = async () => {
  console.log('hey ');
  console.log(userStore.user);


  const newUserSettings = { ...userStore.user }
  newUserSettings.userSettings.apikey = makeId(26)
  userStore.setUser({ auth: { user: newUserSettings } })
  await postChange(newUserSettings);
}
const postChange = async (newData) => {

  await postUserSettings({
    settings: { email: userStore.userEmail, newSettings: newData },
  });
}


</script>

<style lang="scss">
@import '@/styles.scss';
</style>
