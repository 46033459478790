import { getSizes } from "@/api/background-api";
import { set, getAll, clear } from "@/cache/idb";

import { defineStore } from 'pinia'

export const useSizesStore = defineStore('sizes', {
    state: () => ({
        allSizes: [
            // { text: "180x150", value: [180, 150] },
            // { text: "930x180", value: [930, 180] },
            // { text: "930x600", value: [930, 600] },
            // { text: "980x360", value: [980, 360] },
            // { text: "980x300", value: [980, 300] },
            // { text: "980x400", value: [980, 400] },
            // { text: "580x500", value: [580, 500] },
            // { text: "980x120", value: [980, 120] },
            // { text: "980x480", value: [980, 480] },
            // { text: "980x200", value: [980, 200] },
            // { text: "980x600", value: [980, 600] },
            // { text: "980x150", value: [980, 150] },
            // { text: "970x90", value: [970, 90] },
            // { text: "980x90", value: [980, 90] },
            // { text: "990x90", value: [990, 90] },
            // { text: "990x180", value: [990, 180] },
            // { text: "250x600", value: [250, 600] },
            // { text: "250x250", value: [250, 250] },
            // { text: "250x360", value: [250, 360] },
            // { text: "728x90", value: [728, 90] },
            // { text: "960x180", value: [960, 180] },
            // { text: "960x200", value: [960, 200] },
            // { text: "970x170", value: [970, 170] },
            // { text: "970x250", value: [970, 250] },
        ]

    }),
    getters: {
        allSizesSorted(state) {
            return [...state.allSizes]
                .sort((a, b) => a.value[0] - b.value[0]);
        }
    },
    actions: {
        addSize(size){
            this.allSizes.push(size)

        },
        async getAdSizes() {

            const values = await getAll('sizes')
            if (Array.isArray(values) && values.length > 0) {
                this.allSizes = values
            }
            const sizes = await getSizes()
            clear('sizes')
            sizes.Items.forEach(size => {
                set('sizes', size.text, size)
            })
            this.allSizes = sizes.Items


        }
    }

})