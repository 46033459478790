
import { getPupOpportunities } from "@/api/background-api";
let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
import dayjs from "dayjs";

import { defineStore } from 'pinia'

export const useReportStore = defineStore('reporting', {
    state: () => ({
        publishersLoading: false,

        publisherOpportunities: [],

    }),
    getters: {




    },
    actions: {

        async LOAD_POPUPP() {
            this.publishersLoading = true

            let allPupOpps = await getPupOpportunities();
            this.publisherOpportunities = allPupOpps

            this.publishersLoading = false
        }
    }

})