<template>
  <!-- <v-card flat :color="model ? themeColor : null" class="ma-2" variant="outlined">

    <v-card-title class="text-left text-h5">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-left text-h6 text-black">
      {{ text }} </v-card-subtitle>
    <v-banner min-width="200" class="my-4" :color="themeColor" lines="one">
      <cncpt-text-field :hideDetails="hideDetails" bgColor="#f8f8ff" :disabled="disabled" :rounded="rounded"
        v-model="model" :label="label" />

    </v-banner>
<slot name="append"></slot>
  </v-card> -->


  <v-card flat :color="model ? mainStore.themeSubColor : 'grey'" rounded="xl" class="text-left" :subtitle="text" :title="title">
    <!-- <template v-slot:title >
      <span class="font-weight-black"> {{ title }}</span>
    </template> -->

    <v-card-text class="bg-surface-light pt-4">
      <cncpt-text-field :hideDetails="hideDetails" bgColor="#f8f8ff" :disabled="disabled" :rounded="rounded"
        variant="solo" v-model="model" :label="label" :type="type" /> </v-card-text>
    <slot name="append"></slot>
  </v-card>

</template>


<script setup>
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

const model = defineModel()


defineProps({
  title: { Type: String },
  type: {
    type: String,
    default: 'text'
  },
  text: { Type: String, default: "" },
  label: { Type: String, default: "" },
  disabled: { Type: Boolean, default: false },
  rounded: { Type: Boolean, default: false },
  hideDetails: { type: Boolean, default: true },
})

</script>


<style lang="scss">
@import "@/styles.scss";
</style>
