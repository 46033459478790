<template>
    <v-btn class="float-left" variant="text" :color="mainStore.mainColor" @click="logout" prepend-icon="mdi-logout">Log
        out</v-btn>
</template>
<script setup>
// Composition API
import { useAuth0 } from '@auth0/auth0-vue';
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const auth0 = useAuth0();
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()

const logout = () => {
    userStore.LOG_OUT()
    auth0.logout({
        logoutParams: {
            returnTo: window.location.origin
        }
    });

}

</script>


