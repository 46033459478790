
<script setup>
import { defineModel, computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const model = defineModel({ type: String, default: '' })
const model1 = computed({
    // getter
    get() {
        return new Date(model)
    },
    // setter
    set(newValue) {
        console.log('NewValue', newValue);

        emit('update:modelValue', newValue)
        // Note: we are using destructuring assignment syntax here.
    }
})

defineProps({
    label: {
        type: String,
        default: ''
    },
    bgColor: {
        type: String,
        default: '#dadce0'
    }
})

// const emitChange = value => emit('change', value)


</script>

<template>
    <!-- <v-text-field @update:modelValue="emitChange" rounded variant="solo" :bg-color="bgColor" flat v-model="model"
        :label="label" /> -->
    <!-- {{ model }} {{ realDate }} -->
    <v-date-picker v-model="model1" ></v-date-picker>
</template>

<style lang="scss" scoped>
@import "@/styles.scss";
</style>