<script setup>
import { defineModel } from 'vue'
const model = defineModel({ default: '' })
// const searchModel = defineModel('search', { required: true });
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const emit = defineEmits(['change'])

// const props =
defineProps({
  label: {
    type: String,
    default: ''
  },
  bgColor: {
    type: String,
    default: '#dadce0'
  },
  items: {
    type: Array,
    require: true
  },
  inputLabel: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  itemTitle: {
    type: String,
    default: 'title'
  },
  itemValue: {
    type: String,
    default: 'value'
  },
  rounded: {
    type: Boolean,
    default: false
  },
  menuProps: {
    type: Object,
    default: () => { }
  },


})
// const clearSearch = () => search.value = "";


import { ref, } from 'vue'
let search = ref('')

const clear = () => search.value = ''
const emitChange = value => emit('change', value)



// const filteredList = computed(() => {
//   if (!search.value) return props.items
//   return props.items.filter((e) => {

//     let valToSearch = e.overwriteName || e.title || e.text || e;

//     let searchFunc =
//       valToSearch.toLowerCase().indexOf((search.value || "").toLowerCase()) > -1;
//     return searchFunc;
//   })
// })



</script>

<template>


  <v-autocomplete :menu-props="menuProps" v-model:search="search" :eager="false" @click="clear" :color="mainStore.themeColor"
    :item-title="itemTitle" :item-value="itemValue" :multiple="multiple" @update:modelValue="emitChange"
    :bg-color="bgColor" flat v-model="model" hide-details="auto" variant="solo" :rounded="rounded" :disabled="disabled"
    :label="inputLabel" density="compact" :items="items">

  </v-autocomplete>
</template>

<style lang="scss">
@import "@/styles.scss";
</style>