const helperTexts = {
  test1: "This is from helperTexts.js",
  cookieLessAdservers: ["GAM", "Adnuntius"],
  implementationTypes: [
    { title: "NORMAL", value: "NORMAL" },
    { title: "CONCEPTX (CXBA)", value: "CONCEPTX" },
    { title: "CLEANROOM", value: "CLEANROOM" }
  ],
  cmps: ["Sourcepoint", "Cookiebot"],
  editedIndex: -1,
  allowDublicatedTitle: "Allow Dublication of div ids",
  dublicatesText:
    "This will allow you to insert many of the same div-elements. This can be especially useful if you have infinite scroll or very dynamic content",
  trackingTitle: "Write the name to categorise as (your sitename, etc.)",
  periodPlaceholder: "A number that represent how often your data will repeat it self",
  severityPlaceholder: "A number between 0 and 1",
  trackingText: "Analyse your ads trafic",
  loadPeriodText: "The period of your data",
  severityText: "How severe should and anomaly be to be an alert",
  loadPeriodHelperText: "If the dataset contains 100 datapoint and your data repeats itself after every 10 point. Then 10 is your period",
  cncptSeverityLimitHelpterText: "How severe should the anomaly be, before we recieve an alert. 1 is most severe, while 0 is least severe.",
  boosterTitle: "Activate and insert Booster",
  boosterText:
    "This will insert a HTML skelton and populate it with the widget you provide in the settings below.",
  dependText:
    "This is used in combination with adunits 'depends on' to requests ads after there dependencies have been resolved (typically for skies vs lb1)",
  dependTitle: "Load sky after dependencies",
  prebidText:
    "This is a optimization flag for prebid (this needs to be active if prebid should run)",
  s2sText:
    "This will active some config for s2s",
  prebidTitle: "Use prebid",
  s2sTitle: "Active serverside bidding",
  quintesseText:
    "This service help categorize your content for more powerful targeting",

  quintesseTitle: "Load the Quintesse service",
  consentTitle: "Check for consent",
  JWImplementTitle: "Activate JW Player prebid preroll",
  JWImplementText: "This will hook up on an existing JW Player implementation and add a prebid auction before it",
  pubxTitle: "Activate PubX ",
  pubxText: "Activating this will start the pubx test period (14 days). Remember to include the actual module in the prebid file your using.",
  pubxActivateText: "Activating this will include the pubX settings and set automatic pricefloors. Remember to include the actual module in the prebid file your using.",
  rootMarginText: `The amount of pixels before the ad will load, when a user
      scrolls (x2 on mobile). This global will be applied to all
      lazyloaded adunit, unless a specifc value is given for an
      adunit`,
  consentText:
    "The library will wait for TCF2 consent before loading ads (can be used in combination with cookieless)",
  initialText:
    "If active, no ads will load. The ads will have to be 'manually' requested (good for Single-page-applications)",
  initialTitle: "Site should request ads though our API",
  postTitle: "Should this be used as postbid",
  postText:
    'This will remove entry=first key-value and insert pb=["post"]',
  passbackTitle: "Should this be used as passback",
  passbackText:
    "Used in the backend code to make it easier to work with safeframes and tracking. This will cause events and logs not to be emitted",


  // TOOLTIPS BASIC
  ttSite: "Site URL is displayed here.",
  ttSiteLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic",

  ttAduntiusContainer: "Adnuntius container ID",
  ttAduntiusContainerLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#adnuntius",

  ttAdnuntiusSiteID: "Adnuntius site ID",
  ttAdnuntiusSiteIDLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#adnuntius",

  ttGlobalNetworkCode: "Global network code (5706918, MTM or external GAM)",
  ttGlobalNetworkCodeLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#global-network-code",


  ttUsedAsPostbid: "Should this be used as postbid",
  ttUsedAsPostbidLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#postbid",

  ttUsedAsPassback: "Should this be used as passback",
  ttUsedAsPassbackLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#passback",

  ttPubRefText: "Salesforce Publisher opportunity ids",
  ttPubRefTextLink: "https://docs.cncpt.dk/concept/settings/general-settings/basic#salesforce",

  // TOOLTIPS EXTERNAL SERVICES
  ttAdnamiRef: "Adnami reference",
  ttAdnamiRefLink: "https://docs.cncpt.dk/concept/settings/general-settings/services#adnami-reference",


  ttBrowsiRef: "Browsi is an AI comany inserting adunit",
  ttBrowsiRefLink: "https://docs.cncpt.dk/concept/settings/general-settings/services",

  ttLoadQuintesseService: "Load the Quintesse service",
  ttLoadQuintesseServiceLink: "https://docs.cncpt.dk/concept/settings/general-settings/services#quintesse",


  // TOOLTIPS EXTERNAL PREBID
  ttUsePrebid: "This is a optimization flag for prebid (this needs to be active if prebid should run)",
  ttUsePrebidLink: "https://docs.cncpt.dk/concept/settings/general-settings/prebid#use-prebid",

  ttSpecificPrebidCode: "A link to the specific prebid code",
  ttSpecificPrebidCodeLink: "https://docs.cncpt.dk/concept/settings/general-settings/prebid#prebid-code",

  ttInputToDentsuProject: "Input to the Dentsu project",
  ttInputToDentsuProjectLink: "https://docs.cncpt.dk/concept/settings/general-settings/prebid#input-to-dentsu-project",

  ttUsePubx: "Will insert pubx adapter and setup pricefloor module (if its included in prebid)",
  ttUsePubxLink: "https://docs.cncpt.dk/concept/settings/general-settings/prebid#active-pubx-test-period",


  // TOOLTIPS EXTERNAL TRACKING
  ttAnalyseAdTraffic: "Analyse your ads traffic",
  ttAnalyseAdTrafficLink: "https://docs.cncpt.dk/concept/settings/general-settings/tracking#ad-traffic",



  // TOOLTIPS EXTERNAL UI + BOOSTER
  ttCustomFeatures: "Custom features",
  ttCustomFeaturesLink: "https://docs.cncpt.dk/concept/settings/general-settings/ui-+-booster#custom-features",

  ttCustomCSS: "Custom CSS",
  ttCustomCSSLink: "https://docs.cncpt.dk/concept/settings/general-settings/ui-+-booster#custom-css",

  ttInsertBooster: "Insert booster into the page",
  ttInsertBoosterLink: "https://docs.cncpt.dk/concept/settings/general-settings/ui-+-booster#booster",

  // TOOLTIPS page URL
  ttPageUrl: "The field will set the page_url to google",
  ttPageUrlLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#page-url",

  // TOOLTIPS AD SERVING FUNCTIONALITY ADVANCED
  ttKeyValues: "Key values",
  ttKeyValuesLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#key-values",

  ttCheckConsent: "Check for consent",
  ttCheckConsentLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#consent-check",

  ttTestCMP: "CMP to use",
  ttTestCMPLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#cmp-to-use",

  ttDoNotCallInitialAds: "Don't call initial ads",
  ttDoNotCallInitialAdsLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#calling-initial-ads",


  ttSelectCookilessAdserver: "Select a cookieless adserver if you want",
  ttSelectCookilessAdserverLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#cookieless-adserver",

  ttLazyLoadMargin: "Lazy load margin",
  ttLazyLoadMarginLink: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#lazy-load-margin",

  // TOOLTIPS SUPER ADMIN
  ttInvertSearchSettings: "Inverting your search settings will set all the checked fields to unchecked and the unchecked fields to checked",

  // AB TESTS
  ttABTest: "For testing various parameters on two different ads up against each other, you can set up and run an A/B test.",
  ttABTestLink: "https://docs.cncpt.dk/concept/settings/a-b-testing",
  // AB TESTS
  ttJWImplement: "https://docs.cncpt.dk/concept/settings/general-settings/advanced#calling-initial-ads",
  ttJWImplementLink: "This is for running a prebid auction before the video in the JWPlayer",
};

export { helperTexts };
