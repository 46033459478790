<template>
  <!-- <v-card flat :color="model ? themeColor : null" class="ma-2" variant="outlined">

    <v-card-title class="text-left text-h5">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-left text-h6 text-black">
      {{ text }} </v-card-subtitle>
    <v-banner min-width="200" max-width="1200" class="my-4" :color="themeColor" lines="one">
      <cncpt-basic-select :multiple="allowMultiple" :label="label" :color="model ? themeColor : null" :itemName="itemTitle" :itemValue="itemValue" v-model="model" :items="items" />
      <v-banner-text >



      </v-banner-text>

    </v-banner>

  </v-card> -->
  <v-card flat :color="model ? mainStore.themeSubColor : 'grey'" rounded="xl" class="text-left" :subtitle="text" :title="title">
    <!-- <template v-slot:title >
      <span class="font-weight-black"> {{ title }}</span>
    </template> -->

    <v-card-text class="bg-surface-light pt-4">
      <cncpt-basic-select :multiple="allowMultiple" :label="label" :color="model ? mainStore.themeSubColor : null" :itemName="itemTitle" :itemValue="itemValue" v-model="model" :items="items" />

</v-card-text>
    <slot name="append"></slot>
  </v-card>

</template>


<script setup>
// import DisplayHoverTooltip from "@/components/elements/display/DisplayHoverTooltip";
import { defineModel } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()
const model = defineModel()


defineProps({
  title: { Type: String },
  text: { Type: String, default: "" },
  items: { Type: Array },
  itemTitle: {
    type: String,
    default: 'title'
  },
  itemValue: {
    type: String,
    default: 'value'
  },
  isAdmin: { Type: Boolean },
  allowMultiple: { Type: Boolean, default: false },
  helperText: { Type: String, default: "" },
  label: { Type: String, default: "" },
})

</script>


<style lang="scss">
@import "@/styles.scss";
</style>
