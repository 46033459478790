import fetch from "node-fetch";

// let baseUrl =
//   process.env.NODE_ENV === "production"
//     ? "https://5zu65cmx93.execute-api.eu-west-1.amazonaws.com/production/"
//     : // ? "https://concept-auth.azurewebsites.net/api/authorize?code=w5hRGNEucOLW7H/QmR/Y9ZEnqLdww81fPAeOfZMMs0zzjudsYax6Og==&token="
//     // "https://5zu65cmx93.execute-api.eu-west-1.amazonaws.com/production/";
//     "http://localhost:3006/dev/";

const getBaseUrl = () => {
  let apiGW = `execute-api.eu-west-1.amazonaws.com`
  let environments = {
    production: `https://5zu65cmx93.${apiGW}/production`,
    stage: `https://ga5oe6fy1c.${apiGW}/stage`,
    // development: `http://localhost:3006/dev`,
    development: `https://ga5oe6fy1c.${apiGW}/stage`,

  };
  return environments[process.env.NODE_ENV] || environments.development;
};



const getAuthorizationForUser = async (token) => {
  try {
    let headers = { Authorization: 'Bearer ' + token }


    let res = await fetch(`${getBaseUrl()}/authorization`, { headers });
    return res.json();
  } catch (e) {
    console.log("NOOOO");

    return Promise.reject(e);
  }
};



export { getAuthorizationForUser };
