<template>
  <v-banner lines="one" density="compact">
    <v-banner-text style="font-size: 18px; width: 40%; text-align: left">
      <v-tooltip location="top" v-if="!hideTooltip">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="mr-4">mdi-information-outline</v-icon>


        </template>
        <span>{{ helperText }}</span>
      </v-tooltip>
      {{ title }}
    </v-banner-text>

    <v-slider :disabled="!isAdmin" :max="max" :min="min" :step="step" v-model="model" :label="getLabel">
      <template v-slot:append>
        <v-text-field v-model="model" density="compact" style="width: 100px" type="number" variant="outlined"
          hide-details></v-text-field>
      </template>
    </v-slider>





  </v-banner>



</template>


<script setup>
import { defineModel, computed } from 'vue'
// import { useMainStore } from '@/stores/main'

// const mainStore = useMainStore()
const model = defineModel()




// const incementChanges = () => {
//   mainStore.INCREMENT_DEPLOY_CHANGES()
// }
const props = defineProps({
  title: { Type: String },
  isAdmin: { Type: Boolean },
  helperText: { Type: String, default: "" },
  label: { Type: String, default: "" },
  max: { Type: Number, default: 1000 },
  min: { Type: Number, default: 100 },
  step: { Type: Number | String, default: 1 },
  labelFunction: { type: Function },
  hideTooltip: { type: Boolean, default:false },
  suffixLabel: { String, default: 'px' },

})

let getLabel = computed(() => props.labelFunction ? props.labelFunction(model.value) : `${model.value}${props.suffixLabel}`)

</script>


<style lang="scss" scoped>
@import "@/styles.scss";
</style>
