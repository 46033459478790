<template>
  <!-- <v-card flat :color="model ? themeColor : null" class="ma-2" variant="outlined">

    <v-card-title class="text-left text-h5">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="text-left text-h6 text-black text-wrap">
      {{ text }} </v-card-subtitle>
    <v-banner min-width="200" max-width="1000" class="my-4" :color="themeColor" lines="one">
      <v-slider :disabled="!isAdmin" :max="max" :min="min" :step="step" v-model="model" :label="getLabel">
        <template v-slot:append>
          <v-text-field
            v-model="model"
            density="compact"
            style="width: 100px"
            type="number"
            variant="outlined"
            hide-details
          ></v-text-field>
        </template>
      </v-slider>
      <v-banner-text>




    </v-banner>

  </v-card> -->


  <v-card flat :color="model ? mainStore.themeSubColor : 'grey'" rounded="xl" class="text-left" :subtitle="text" :title="title">

    <v-card-text class="bg-surface-light pt-4">
      <v-slider :disabled="!isAdmin" :max="max" :min="min" :step="step" v-model="model" :label="getLabel">
        <template v-slot:append>
          <v-text-field
            v-model="model"
            density="compact"
            style="width: 100px"
            type="number"
            variant="outlined"
            hide-details
          ></v-text-field>
        </template>
      </v-slider>

</v-card-text>
  </v-card>


</template>

<script setup>
import { defineModel, computed } from 'vue'
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

let props = defineProps({
  title: { Type: String },
  text: { Type: String },
  suffixLabel: { String, default: 'px' },
  isAdmin: { Type: Boolean },
  max: { Type: Number, default: 1000 },
  min: { Type: Number, default: 100 },
  step: { Type: Number | String, default: 1 },
  labelFunction: { type: Function }


})

const model = defineModel()

let getLabel = computed(() => props.labelFunction ? props.labelFunction(model.value) : `${model.value}${props.suffixLabel}`)

</script>

<style lang="scss">
@import "@/styles.scss";
</style>