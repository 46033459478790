import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore'



const firebaseConfig_prod = {
  apiKey: "AIzaSyB9YpmVCdiBh8xaHQ2-zQm6KlRdx_wzNLM",
  authDomain: "apday-dashbord-prod.firebaseapp.com",
  databaseURL: "https://apday-dashbord-prod.firebaseio.com",
  projectId: "cncpt-cph",
  storageBucket: "apday-dashbord-prod.appspot.com",
  messagingSenderId: "477153454626",
  appId: "1:477153454626:web:6ef383f9ccea94a645387b"
};


export const firebaseApp = initializeApp(firebaseConfig_prod)

// used for the firestore refs
const db = getFirestore(firebaseApp)

export const siteSettingsRef = collection(db, 'siteSettings')
export const siteUserWatchingsRef = collection(db, 'siteUserWatching')
export const deployTimesRef = collection(db, 'deployTimes')
export const productOwnersRef = collection(db, 'productOwners')
export const OwnedProductsRef = collection(db, 'OwnedProducts')
export const notificationsRef = collection(db, 'notifications')


export const getItem = async (collection, id) => {
  const docRef = doc(db, collection, id);
  const docSnap = await getDoc(docRef);
  return docSnap
}
export const setItem = async (collection, id, newData) => {
  const docRef = doc(db, collection, id);
  const docSnap = await setDoc(docRef, newData);
  return docSnap
}
export const deleteItem = async (collection, id) => {
  const docRef = doc(db, collection, id);
  const docSnap = await deleteDoc(docRef)
  return docSnap
}